import React, { useState, useEffect } from "react";
import FormElement from "../FormElement";

import passengersIcon from "../../../../images/passengers-icon.svg";

export default ({
  label,
  label_adult = "Adult",
  label_adult_prural = "Adults",
  label_child = "Child",
  label_child_prural = "Children",
  label_infant = "Infant",
  label_infant_prural = "Infants",
  id_adult = "adults",
  id_child = "children",
  id_infant = "infants",
  className = "",
  type,
  id,
  input_id,
  icon = passengersIcon,
  name,
  track_name,
  note_adult = "12+",
  note_child = "2-12",
  note_infant = "0-1",
  default_value,
  max_passengers_per_category = 7,
  apply_button = "Apply",
  onChange
}) => {
  const [shown, setShown] = useState(false);
  const [value, setValue] = useState(
    default_value
      ? default_value
      : {
          adult: 1,
          child: 0,
          infant: 0
        }
  );

  useEffect(() => {
    // Just once update form with default value
    if (onChange) {
      onChange(id_adult, value[id_adult]);
      onChange(id_child, value[id_child]);
      onChange(id_infant, value[id_infant]);
    }
  }, []);

  const renderAddRemovePassengers = subfield => {
    return (
      <div className="Passenger-dropdown-buttons">
        <button
          className={
            (subfield === id_adult
            ? value[subfield] > 1
            : value[subfield] >= 1)
              ? ""
              : "disabled"
          }
          onClick={e => {
            e.preventDefault();
            const newValue =
              subfield === id_adult
                ? value[subfield] > 1
                  ? value[subfield] - 1
                  : 1
                : value[subfield] >= 1
                ? value[subfield] - 1
                : 0;
            setValue(value => ({
              ...value,
              [subfield]: newValue
            }));
            if (onChange) onChange(subfield, newValue);
          }}
        >
          <span>-</span>
        </button>
        {value[subfield]}
        <button
          onClick={e => {
            e.preventDefault();
            const newValue =
              value[subfield] < max_passengers_per_category
                ? value[subfield] + 1
                : max_passengers_per_category;
            setValue(value => ({
              ...value,
              [subfield]: newValue
            }));

            if (onChange) onChange(subfield, newValue);
          }}
        >
          <span>+</span>
        </button>
      </div>
    );
  };

  return (
    <FormElement className={className} type={type} label={label} icon={icon}>
      <div className="input">
        <div
          className="control"
          onClick={e => {
            e.preventDefault();
            console.log("jey");
            setShown(!shown);
          }}
        >
          <span>{value[id_adult] + value[id_child] + value[id_infant]}</span>
        </div>

        {shown && (
          <div className="Passenger-dropdown">
            <div>
              <div>
                {`${value.adult < 2 ? label_adult : label_adult_prural}`}
                <span>{note_adult}</span>
              </div>
              {renderAddRemovePassengers(id_adult)}
            </div>
            <div>
              <div>
                {`${value.child < 2 ? label_child : label_child_prural}`}
                <span>{note_child}</span>
              </div>
              {renderAddRemovePassengers(id_child)}
            </div>
            <div>
              <div>
                {`${value.infant < 2 ? label_infant : label_infant_prural}`}
                <span>{note_infant}</span>
              </div>
              {renderAddRemovePassengers(id_infant)}
            </div>
            <button
              className="Passenger-dropdown-button"
              onClick={e => {
                e.preventDefault();
                setShown(!shown);
              }}
            >
              {apply_button}
            </button>
          </div>
        )}
      </div>
    </FormElement>
  );
};
