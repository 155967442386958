import React, { useState, useEffect, useRef } from "react";
import parser from "html-react-parser";
import Select from "react-select";

import FormElement from "../FormElement";

import { isNode } from "../../../../utils";

let asyncSearchTimeout, focusTimeout;
export default ({
  label,
  className = "",
  type,
  id,
  input_id,
  icon,
  name,
  track_name,
  options,
  async_options,
  async_options_on_search,
  async_options_on_search_min_letters = 3,
  theme,
  default_value,
  appendID = false,
  searchable = false,
  loading_message = "Loading",
  placeholder = `Select ${label}`,
  no_results_message = "No results",
  required = false,
  onChange
}) => {
  if (!options && !async_options) return null;

  const selectRef = useRef(null);
  const [focusedOnce, setFocusedOnce] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [optionsSelect, setOptionsSelect] = useState(options || []);

  const [value, setValue] = useState(
    default_value
      ? default_value
      : optionsSelect
      ? optionsSelect[0]
        ? optionsSelect[0].options
          ? optionsSelect[0].options[0]
            ? optionsSelect[0].options[0].id
            : null
          : optionsSelect[0].id
        : null
      : null
  );

  useEffect(() => {
    const locationInput = isNode ? null : document.getElementsByName(name);
    if (locationInput && locationInput[0])
      locationInput[0].setAttribute("data-track-name", track_name || name);
  }, [isNode]);

  useEffect(() => {
    if (async_options) {
      try {
        async_options()
          .then(optionsResult => {
            if (optionsResult && optionsResult.length) {
              setOptionsSelect(optionsResult);

              const defaultOption =
                default_value || optionsResult[0] ? optionsResult[0].id : null;
              setValue(defaultOption);
              if (onChange) onChange(id, defaultOption);
            }
          })
          .catch(e => {
            console.log("Select async options error: ", e);
          });
      } catch (e) {
        console.log("Select async options error: ", e);
      }
    }
  }, []);

  useEffect(() => {
    // Just once update form with default value
    if (onChange) onChange(id, value);

    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

    return () => {
      clearTimeout(asyncSearchTimeout);
      clearTimeout(focusTimeout);
    };
  }, []);

  const getValue = locKey => {
    if (!optionsSelect) return null;

    for (let i = 0; i < optionsSelect.length; i++) {
      if (optionsSelect[i].options) {
        for (let j = 0; j < optionsSelect[i].options.length; j++) {
          if (optionsSelect[i].options[j].id === locKey)
            return optionsSelect[i].options[j];
        }
      } else if (optionsSelect[i].id === locKey) return optionsSelect[i];
    }
    return null;
  };

  const getValueOption = locId => {
    if (!locId) return null;

    const loc = getValue(locId);
    if (loc) {
      return {
        value: loc.id,
        label: `${loc.value}${appendID ? ` (${loc.id})` : ""}`
      };
    }
    return null;
  };

  const onFocus = focus => {
    if (focus) setFocusedOnce(true);
  };

  const onInputChange = (val, { action }) => {
    clearTimeout(asyncSearchTimeout);
    if (async_options_on_search) {
      if (action === "input-change") {
        if (val && val.length >= async_options_on_search_min_letters) {
          try {
            asyncSearchTimeout = setTimeout(() => {
              async_options_on_search(val)
                .then(optionsResult => {
                  if (optionsResult && optionsResult.length) {
                    setOptionsSelect(optionsResult);

                    setValue(default_value);
                    if (onChange) onChange(id, default_value);
                  }
                })
                .catch(e => {
                  if (optionsSelect.length > 0) setOptionsSelect([]);
                  console.log("Select async options on search error: ", e);
                });
            }, 60);
          } catch (e) {
            if (optionsSelect.length > 0) setOptionsSelect([]);
            console.log("Select async options on search error: ", e);
          }
        } else {
          if (optionsSelect.length > 0) setOptionsSelect([]);
        }
      }
    }
  };

  const onMenuOpen = () => {
    clearTimeout(focusTimeout);
    focusTimeout = setTimeout(() => {
      const scapeID = (input_id || id).replace("[", `\\[`).replace("]", `\\]`);
      const wrapper = document.querySelector(`.select#${scapeID}`);
      if (wrapper) {
        const selectScroll = wrapper.getElementsByClassName(
          "select__menu-list"
        );
        const selectedOption = wrapper.getElementsByClassName(
          "select__option--is-selected"
        );
        if (
          selectedOption &&
          selectedOption[0] &&
          selectScroll &&
          selectScroll[0]
        ) {
          selectScroll[0].scrollTop = selectedOption[0].offsetTop;
        }
      }
    }, 0);
  };

  return (
    <FormElement className={className} type={type} label={label} icon={icon}>
      <Select
        ref={selectRef}
        className={`select ${searchable ? "searchable" : ""} ${
          focusedOnce ? "focused-once" : ""
        } ${isSafari ? "is-safari" : ""}`}
        classNamePrefix="select"
        id={input_id || id}
        name={name}
        placeholder={placeholder}
        required={required}
        isMulti={false}
        isSearchable={searchable}
        onFocus={onFocus}
        onBlur={() => {
          clearTimeout(focusTimeout);
        }}
        value={getValueOption(value)}
        formatOptionLabel={(option) => {
          return <span dangerouslySetInnerHTML={{ __html: option.label }} />
        }}
        options={optionsSelect.map(option =>
          option.options
            ? {
                label: <span dangerouslySetInnerHTML={{ __html: option.label }} />,
                options: option.options.map(option2 => ({
                  value: option2.id,
                  label: `${option2.value} ${
                    appendID ? ` (${option2.id})` : ""
                  }`
                }))
              }
            : {
                value: option.id,
                label: `${option.value} ${appendID ? ` (${option.id})` : ""}`
              }
        )}
        onChange={selectedOption => {
          setValue(selectedOption.value);
          if (onChange) onChange(id, selectedOption.value);
        }}
        loadingMessage={() => loading_message}
        noOptionsMessage={() => parser(no_results_message)}
        onInputChange={onInputChange}
        theme={theme}
        onMenuOpen={onMenuOpen}
      />
    </FormElement>
  );
};
