import React from "react";
import classnames from "classnames";

import "./styles.scss";

import useLoadImage from "./useLoadImage";

import renderImage from "./renderImage";
import renderBackgroundImage from "./renderBgImage";

import { isNode } from "../../utils";

const LazyImg = props => {
  const state = useLoadImage(props);
  const {
    onClick,
    className = "",
    isBackground,
    backgroundColor,
    style
  } = props;
  const opacityStyle = {
    opacity: state.status === "LOADED" || isNode ? 1 : 0
  };

  return (
    <span
      style={
        backgroundColor ? { ...style, background: backgroundColor } : style
      }
      className={classnames("LazyImg", className)}
      onClick={() => !!onClick && onClick()}
    >
      {isBackground
        ? renderBackgroundImage(state, opacityStyle, props)
        : renderImage(state, opacityStyle, props)}
    </span>
  );
};

export default LazyImg;
