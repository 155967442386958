import React, { useState } from "react";
import parser from "html-react-parser";

import LazyImg from "../../LazyImg/LazyImg";

import "./styles.scss";

export default ({ title, benefits = [] }) => {
  const [popUpOpenedId, setPopUpOpenedId] = useState(-1);

  const togglePopUp = id => () => {
    if (popUpOpenedId !== id) {
      setPopUpOpenedId(id);
    } else {
      setPopUpOpenedId(-1);
    }
  };

  return benefits && benefits.length > 0 ? (
    <div className="Benefits">
      <div className="container">
        <div className="Benefits-wrapper">
          <h2>{title}</h2>
          <ul>
            {benefits.map((benefit, index) => (
              <li className="Benefit" key={benefit.text}>
                <div onClick={togglePopUp(index)}>
                  <LazyImg className="Benefit-image" src={benefit.icon} />
                  <p>{benefit.text}</p>
                  {benefit.popup_text && (
                    <div
                      className={`Benefit-popup ${
                        popUpOpenedId === index ? "opened" : ""
                      }`}
                    >
                      <span className="screen" onClick={togglePopUp(index)} />
                      <div>
                        <button
                          className="popup-close"
                          onClick={togglePopUp(index)}
                        >
                          <span />
                          <span />
                        </button>
                        {parser(benefit.popup_text)}
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};
