import React from "react";

import useData from "../../hooks/useData";

import "./styles.scss";

export default () => {
  const pageData = useData();

  return (
    <footer>
      <div className="container">
        {pageData.footer_copyright && (
          <p className="Footer-copy">{pageData.footer_copyright}</p>
        )}
        <div className="Footer-links">
          {(pageData.footer_links || []).map(link => (
            <a key={link.text + link.url} href={link.url}>
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};
