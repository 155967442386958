import React, { useState } from "react";
import PropTypes from "prop-types";
import parser from "html-react-parser";

import useData from "../../../hooks/useData";

import { HOURS } from "../../../constants";

import arrowRightYellow from "../../../images/arrow-right-yellow.svg";
import exchangeFieldsIcon from "../../../images/exchange-fields.svg";

import { Select, Date, Checkbox, Passengers, Hidden } from "./Fields";
import {
  isValidDate,
  addDaysToDate,
  formatDateForSubmit,
  parseFormattedDate
} from "./Fields/Date";

import "./styles.scss";

const selectTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: "#eee",
    primary: "#542e91"
  }
});

const getFormParamsString = (fields, params, formData) => {
  let paramsString = "";

  if (!params) return paramsString;

  for (let index = 0; index < params.length; index++) {
    const el = params[index];
    let hide = false;
    for (let i = 0; i < fields.length; i++) {
      const f = fields[i];
      if (
        el.name &&
        f.fields_to_hide &&
        f.fields_to_hide.length &&
        f.fields_to_hide.length > 0 &&
        f.fields_to_hide.includes(el.name)
      ) {
        if (f.hide_on_true ? formData[f.id] : !formData[f.id]) {
          hide = true;
          break;
        }
      }
    }
    if (!hide) {
      let value =
        el.value && typeof el.value === "string" ? el.value.trim() : el.value;
      let elementWithProps;
      for (let index = 0; index < fields.length; index++) {
        if (el.name === fields[index].name) {
          elementWithProps = fields[index];
          break;
        }
      }

      if (elementWithProps && elementWithProps.transformValue) {
        value = elementWithProps.transformValue(value, params);
      }
      paramsString += `&${el.name}=${value}`;
    }
  }

  return paramsString;
};

const getParamsString = params => {
  let paramsString = "";
  for (let index = 0; index < params.length; index++) {
    const el = params[index];
    paramsString += `&${el.name}=${
      el.value && typeof el.value === "string" ? el.value.trim() : el.value
    }`;
  }
  return paramsString;
};

const getNameValuesFromObj = obj => {
  let returnedObjectList = [];
  Object.keys(obj || {}).forEach(key => {
    returnedObjectList.push({
      name: key,
      value: obj[key]
    });
  });
  return returnedObjectList;
};

const Form = () => {
  const pageData = useData();
  const [formData, setFormData] = useState({});

  const onFieldChange = (id, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const onDateChange = (field, id, value) => {
    if (field.date_id_to_adjust && value) {
      if (formData[field.date_id_to_adjust]) {
        const date = value;
        const nextDate = parseFormattedDate(formData[field.date_id_to_adjust]);
        if (isValidDate(date) && isValidDate(nextDate)) {
          const one_day = 1000 * 60 * 60 * 24;
          const timeDiff = nextDate.getTime() - date.getTime();
          const dayDifference = timeDiff / one_day;
          if (dayDifference <= 0) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [field.date_id_to_adjust]: formatDateForSubmit(
                addDaysToDate(value, 7)
              )
            }));
          }
        }
      }
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value ? formatDateForSubmit(value) : value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const SEARCH_BASE_URL = pageData.form_action_url;

    const URL = `${SEARCH_BASE_URL}?${getFormParamsString(
      pageData.form_fields || [],
      getNameValuesFromObj(formData),
      formData
    )}${getParamsString(
      pageData.form_extra_parameters
        ? pageData.form_extra_parameters(formData)
        : []
    )}`;

    window.location.href = encodeURI(URL);
  };

  const renderField = (pageData, field, index, exchangeLabel = false) => {
    const fields = pageData.form_fields || [];

    for (let i = 0; i < fields.length; i++) {
      const f = fields[i];
      if (
        field.id &&
        f.fields_to_hide &&
        f.fields_to_hide.length &&
        f.fields_to_hide.length > 0 &&
        f.fields_to_hide.includes(field.id)
      ) {
        if (f.hide_on_true ? formData[f.id] : !formData[f.id]) {
          return null;
        }
      }
    }

    switch (field.type) {
      case "time":
      case "select":
        return (
          <Select
            key={field.type + index}
            {...field}
            label={exchangeLabel ? field.second_label : field.label}
            theme={selectTheme}
            onChange={onFieldChange}
            options={field.options || (field.type === "time" ? HOURS : [])}
            appendID={field.append_id_to_select}
            formValue={formData[field.id]}
          />
        );

      case "toggle":
      case "checkbox":
        return (
          <Checkbox
            key={field.type + index}
            {...field}
            label={exchangeLabel ? field.second_label : field.label}
            onChange={onFieldChange}
            formValue={formData[field.id]}
          />
        );
      case "hidden":
        return (
          <Hidden
            key={field.type + index}
            {...field}
            formValue={formData[field.id]}
            onChange={onFieldChange}
          />
        );
      case "date":
        let toAddDaysInitDate = false;
        for (let j = 0; j < fields.length; j++) {
          const f = fields[j];
          if (f.date_id_to_adjust === field.id) {
            toAddDaysInitDate = true;
          } else if (f.fields && f.fields.length > 0) {
            for (let k = 0; k < f.fields.length; k++) {
              const subF = f.fields[k];
              if (subF.date_id_to_adjust === field.id) {
                toAddDaysInitDate = true;
              }
            }
          }
        }
        return (
          <Date
            key={field.type + index}
            {...field}
            className={`${
              index + 1 < pageData.form_fields.length &&
              pageData.form_fields[index + 1].type === "time"
                ? "date-before-time"
                : ""
            }`}
            label={exchangeLabel ? field.second_label : field.label}
            onChange={(id, value) => onDateChange(field, id, value)}
            formValue={formData[field.id]}
            addDaysInitDate={toAddDaysInitDate}
          />
        );
      case "exchangeable_fields":
        if (
          !(
            formData[field.id_change_field] === 0 ||
            formData[field.id_change_field] === 1
          )
        ) {
          onFieldChange(field.id_change_field, 0);
        }
        if (field.fields && field.fields.length >= 2 && field.id_change_field) {
          const fieldOne = renderField(
            pageData,
            field.fields[0],
            1,
            formData[field.id_change_field]
          );

          const fieldTwo = renderField(
            pageData,
            field.fields[1],
            2,
            formData[field.id_change_field]
          );
          return (
            <div key={field.type + index} className="Form-exchangeable-fields">
              {fieldOne &&
                renderField(
                  pageData,
                  field.fields[0],
                  1,
                  formData[field.id_change_field] && fieldTwo
                )}
              {fieldOne && fieldTwo && (
                <button
                  type="button"
                  className={`Form-exchangeable-fields-button button-no-generic ${
                    formData[field.id_change_field] === 1 ? "active" : ""
                  }`}
                  onClick={() =>
                    onFieldChange(
                      field.id_change_field,
                      !formData[field.id_change_field] ? 1 : 0
                    )
                  }
                >
                  <img src={exchangeFieldsIcon} alt="Exchange fields" />
                </button>
              )}
              {fieldTwo &&
                renderField(
                  pageData,
                  field.fields[1],
                  2,
                  formData[field.id_change_field] && fieldOne
                )}
            </div>
          );
        } else {
          return null;
        }
      case "passengers":
        return (
          <Passengers
            key={field.type + index}
            {...field}
            onChange={(id, value) => {
              onFieldChange(id, value);
            }}
          />
        );
      default:
        return null;
    }
  };

  const isThereFieldOfType = type => {
    const fields = pageData.form_fields || [];
    for (let i = 0; i < fields.length; i++) {
      const f = fields[i];
      if (f.type === type) {
        return true;
      }
    }
    return false;
  };

  const formNoteRes =
    pageData.form_note && parser(pageData.form_note(formData) || "");

  return (
    <div className="Form">
      <div className="container-big">
        {pageData.form_fields && pageData.form_fields.length >= 1 && (
          <>
            <h3 className="Form-title">
              {pageData.form_heading_icon && (
                <img src={pageData.form_heading_icon} alt="Form icon" />
              )}
              {pageData.form_heading || `Airport Hotel & Parking`}
            </h3>

            <form
              className={`Form-form ${
                (pageData.form_fields || []).length <= 7 ? "fit" : ""
              } ${isThereFieldOfType("toggle") ? "with-toggle-above" : ""}`}
              onSubmit={handleSubmit}
            >
              {(pageData.form_fields || []).map((field, index) =>
                renderField(pageData, field, index)
              )}

              <button
                className="Form-submit-button"
                data-hxtrack-name="engine-search-button"
                data-track-name="search-button"
                type="submit"
              >
                {pageData.form_submit_text || "Search"}
                <img
                  className="Form-submit-button-arrow-right"
                  src={arrowRightYellow}
                  alt="Form submit button arrow"
                />
              </button>
            </form>
          </>
        )}

        {formNoteRes && formNoteRes !== "" && (
          <p
            className={`Form-note ${
              isThereFieldOfType("checkbox") ? "with-checkbox-above" : ""
            }`}
          >
            {formNoteRes}
          </p>
        )}
      </div>
    </div>
  );
};

Form.propTypes = {
  params: PropTypes.object
};

export default Form;
