import React from "react";
import parser from "html-react-parser";

import "./styles.scss";

export default ({ title, text, text_second_column, list, list_headers }) => (
  <div className="MainContent">
    <div className="container">
      <div className="content">
        {title && title !== "" && (
          <h2 className="MainContent-title">{title}</h2>
        )}
        {text && text.length > 0 && (
          <div
            className={`MainContent-content ${
              text_second_column && text_second_column.length > 0
                ? "two-column"
                : ""
            }`}
          >
            <div>{parser(text)}</div>
            <div>{parser(text_second_column)}</div>
          </div>
        )}
      </div>

      {list && list.length > 0 && (
        <div className="MainContent-list">
          {list_headers && (
            <ul className="MainContent-list-header">
              {list_headers.map(item => (
                <li className="MainContent-list-header-item" key={item}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          )}
          <ul className="MainContent-list-list">
            {list.map((subItem, index) => {
              let sublist = subItem && subItem.length > 0 ? subItem : [subItem];
              return (
                <li
                  className={`MainContent-list-item ${
                    index === 0 ? "first" : ""
                  } ${index === list.length - 1 ? "last" : ""}`}
                  key={sublist + index}
                >
                  {sublist && sublist.length && (
                    <ul className="MainContent-list-sublist">
                      {sublist.map((subItem, i) => (
                        <li
                          className={`MainContent-list-item-item  ${
                            i === 0 ? "first" : ""
                          } ${i === sublist.length - 1 ? "last" : ""}`}
                          key={subItem}
                        >
                          <p>{parser(subItem)}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  </div>
);
