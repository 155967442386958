import React from "react";
import PropTypes from "prop-types";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import "./styles.scss";

const Layout = ({ children }) => (
  <div className="Layout">
    <Navbar />
    <main>{children}</main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
