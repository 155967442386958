import React from "react";

import LazyImg from "../../LazyImg/LazyImg";

import heroImageFallback from "../../../images/hero-fallback.jpg";

import "./styles.scss";

export default ({ image = heroImageFallback }) =>
  image ? (
    <div className="Hero">
      <LazyImg className="Hero-image" src={image} />
    </div>
  ) : null;
