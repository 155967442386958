import React from "react";
import PropTypes from "prop-types";

import clockIcon from "../../../images/form-clock-icon.svg";
import dateIcon from "../../../images/form-date-icon.svg";

const ICONS_BY_TYPE = {
  time: clockIcon,
  date: dateIcon
};

const FormElement = ({
  children,
  className = "",
  type = "input",
  label,
  icon,
  value,
  beforeSmall,
  small,
  onClick = () => null
}) => {
  const iconFixed = icon || ICONS_BY_TYPE[type];

  return (
    <div
      className={`Form-element ${beforeSmall ? "beforeSmall" : ""} ${
        small ? "small" : ""
      } ${iconFixed ? "with-icon" : ""} type-${type} ${className}`}
      onClick={onClick}
    >
      <label>
        {label && <span className="label">{label}</span>}
        <span className="Form-element-in">
          {iconFixed && (
            <span className="icon">
              <img src={iconFixed} alt={`${label} icon`} />
            </span>
          )}
          <span className="value">{value}</span>
          <span className="input">{children}</span>
        </span>
      </label>
    </div>
  );
};

FormElement.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default FormElement;
