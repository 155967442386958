import React, { useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import FormElement from "../FormElement";

import { MONTHS, MONTHS_LONG, WEEK_DAYS } from "../../../../constants";

const formatDate = date => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${WEEK_DAYS[date.getDay()]} ${day} ${
    MONTHS[monthIndex]
  } ‘${`${year}`.slice(-2)}`;
};

export const formatDateForSubmit = date => {
  if (!date || !date.getDate) return;
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${year}-${`0${monthIndex + 1}`.slice(-2)}-${`0${day}`.slice(-2)}`;
};

export const parseFormattedDate = dateString => {
  try {
    const parts = `${dateString}`.split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];
    return new Date(year, month - 1, day);
  } catch (e) {
    console.log(e);
    return new Date();
  }
};

const parseDate = dateString => {
  try {
    const parts = `${dateString}`.replace("‘", "").split(" ");
    const day = parts[1];
    const monthShortName = parts[2];
    const year = `20${parts[3]}`;
    let month = 1;
    for (let i = 0; i < MONTHS.length; i++) {
      if (MONTHS[i] === monthShortName) {
        month = i;
      }
    }

    return new Date(year, month, day);
  } catch (e) {
    console.log(e);
    return new Date();
  }
};

export const addDaysToDate = (date, days) => {
  const nextWeekDate = new Date(date);
  nextWeekDate.setDate(nextWeekDate.getDate() + days);
  return new Date(nextWeekDate);
};

export const addDaysToToday = days => {
  const nextWeekDate = new Date();
  nextWeekDate.setDate(nextWeekDate.getDate() + days);
  return new Date(nextWeekDate);
};

export const isValidDate = date => {
  if (date && Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export default ({
  className = "",
  type,
  label,
  id,
  input_id,
  icon,
  name,
  track_name,
  default_value,
  onChange,
  formValue,
  placeholder = `${label}`,
  addDaysInitDate = false,
  required = false
}) => {
  const [changed, setChanged] = useState(false);
  const [date, setDate] = useState(
    default_value && isValidDate(new Date(default_value))
      ? new Date(default_value)
      : addDaysToToday(addDaysInitDate ? 7 : 1)
  );

  useEffect(() => {
    // Just once update form with default value
    if (onChange && !changed) {
      setChanged(true);
      onChange(id, date);
    }
  });

  useEffect(() => {
    if (formValue && formValue !== date) {
      const newDate = parseFormattedDate(formValue);
      if (isValidDate(newDate)) {
        setDate(newDate);
        setChanged(true);
      }
    }
  }, [formValue]);

  return (
    <FormElement className={className} type={type} label={label} icon={icon}>
      <DayPickerInput
        name={name}
        value={date}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        onDayChange={day => {
          setDate(day);
          setChanged(true);
          if (onChange) {
            onChange(id, day);
          }
        }}
        inputProps={{
          "data-track-name": track_name || name,
          readOnly: true,
          id: input_id || id,
          required
        }}
        dayPickerProps={{
          firstDayOfWeek: 1,
          month: new Date(date),
          months: MONTHS_LONG,
          weekdaysShort: WEEK_DAYS,
          disabledDays: {
            before: new Date()
          },
          selectedDays: new Date(date)
        }}
      />
    </FormElement>
  );
};
