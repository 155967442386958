import React from "react";
import useData from "../../hooks/useData";

import LazyImg from "../../components/LazyImg/LazyImg";

import logoVertical from "../../images/logo-vertical.svg";
import logoHorizontal from "../../images/logo-horizontal.svg";

import "./styles.scss";

export default () => {
  const pageData = useData();
  return (
    <nav>
      <div>
        <a href="https://www.parken-und-fliegen.de" className="Navbar-logo">
          <LazyImg className="Navbar-logo-image vertical" src={logoVertical} />
          <LazyImg
            className="Navbar-logo-image horizontal"
            src={logoHorizontal}
          />
        </a>
        <p className="Navbar-text heading-text">
          {pageData.heading_text || "Weniger Stress. Mehr Urlaub."}
        </p>
      </div>
    </nav>
  );
};
