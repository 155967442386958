export const isNode = !(typeof window !== "undefined" && window !== null);

export const w = !isNode ? window : {};

export const isIE = () => {
  if (!isNode) {
    if (!w.isIE && !w.IEChecked) {
      const ua = w.navigator.userAgent;
      const msie = ua ? ua.indexOf("MSIE ") : null;
      if (
        (msie && msie > 0) ||
        !!navigator.userAgent.match(/Trident.*rv:11\./)
      ) {
        w.isIE = true;
        document.documentElement.classList.add("is-ie");
      }
      // Treat Mircrosoft Edge like IE so no animations and so on
      if (!w.isIE) {
        w.isIE = !!w.StyleMedia;
      }

      w.IEChecked = true;
    }
    return w.isIE;
  }
};
