import React, { useState, useEffect } from "react";
import FormElement from "../FormElement";

import inputCheck from "../../../../images/input-check.svg";

export default ({
  label,
  label_true = "Yes",
  label_false = "No",
  className = "",
  type,
  id,
  input_id,
  icon,
  name,
  track_name,
  default_value,
  onChange
}) => {
  const [value, setValue] = useState(default_value || false);
  useEffect(() => {
    // Just once update form with default value
    if (onChange) onChange(id, value);
  }, []);

  return (
    <FormElement
      className={`${className} ${value ? "checked" : ""}`}
      type={type}
      label={label}
      icon={icon}
    >
      <input
        className="checkbox"
        type="checkbox"
        id={input_id || id}
        name={name}
        checked={value}
        data-track-name={track_name || name}
        onChange={e => {
          const checked = e.target.checked;
          setValue(checked);
          if (onChange) onChange(id, checked);
        }}
      />
      <span />
      {type === "checkbox" && (
        <span className="check">
          <img src={inputCheck} alt="Input checkbox" />
        </span>
      )}
      {type === "toggle" && (
        <span className="toggle-check">
          <span>{label_true}</span>
          <span>{label_false}</span>
        </span>
      )}
    </FormElement>
  );
};
