import React, { useState, useEffect } from "react";
import FormElement from "../FormElement";

export default ({
  className = "",
  type,
  id,
  input_id,
  default_value,
  name,
  track_name,
  onChange
}) => {
  const [value] = useState(default_value || false);
  useEffect(() => {
    // Just once update form with default value
    if (onChange) onChange(id, value);
  }, []);

  return (
    <FormElement className={className} type={type}>
      <input
        className="hidden"
        type="text"
        id={input_id || id}
        name={name}
        value={value}
        data-track-name={track_name || name}
        readOnly
      />
    </FormElement>
  );
};
