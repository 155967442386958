import React from "react";
import useData from "../../hooks/useData";

import Form from "../../components/ContentBlocks/Form/Form";
import Hero from "../../components/ContentBlocks/Hero/Hero";
import MainContent from "../../components/ContentBlocks/MainContent/MainContent";
import Badges from "../../components/ContentBlocks/Badges/Badges";
import Benefits from "../../components/ContentBlocks/Benefits/Benefits";

import "./styles.scss";

const Home = ({ location }) => {
  const pageData = useData();

  const withContent =
    (pageData.content_title && pageData.content_title !== "") ||
    (pageData.content_text && pageData.content_text !== "") ||
    (pageData.content_list && pageData.content_list.length > 0) ||
    (pageData.content_list_headers && pageData.content_list_headers.length > 0);

  return (
    <div className="Home">
      <Form />

      <div className="Home-badges mobile-only">
        <Badges images={pageData.content_badges} />
      </div>

      <Hero image={pageData.hero_image} />

      <div
        className={`content-wrapper ${
          withContent || (pageData.benefits && pageData.benefits.length > 0)
            ? "with-content"
            : ""
        }`}
      >
        {/* Two columns */}
        <div
          className={`Home-content-wrapper ${
            pageData.content_title && pageData.content_title.trim().length > 0
              ? "with-title"
              : ""
          }`}
        >
          <div className="container">
            {withContent && (
              <MainContent
                title={pageData.content_title}
                text={pageData.content_text}
                text_second_column={pageData.content_text_second_column}
                list={pageData.content_list}
                list_headers={pageData.content_list_headers}
              />
            )}
            {pageData.content_badges && pageData.content_badges.length > 0 && (
              <div className="Home-badges">
                <Badges images={pageData.content_badges} />
              </div>
            )}
          </div>
        </div>
        {/* ---- */}

        {pageData.benefits && pageData.benefits.length > 0 && (
          <Benefits
            title={pageData.benefits_title}
            benefits={pageData.benefits}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
