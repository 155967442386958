export default [
  { id: "0000", value: "00:00" },
  { id: "0030", value: "00:30" },
  { id: "0100", value: "01:00" },
  { id: "0130", value: "01:30" },
  { id: "0200", value: "02:00" },
  { id: "0230", value: "02:30" },
  { id: "0300", value: "03:00" },
  { id: "0330", value: "03:30" },
  { id: "0400", value: "04:00" },
  { id: "0430", value: "04:30" },
  { id: "0500", value: "05:00" },
  { id: "0530", value: "05:30" },
  { id: "0600", value: "06:00" },
  { id: "0630", value: "06:30" },
  { id: "0700", value: "07:00" },
  { id: "0730", value: "07:30" },
  { id: "0800", value: "08:00" },
  { id: "0830", value: "08:30" },
  { id: "0900", value: "09:00" },
  { id: "0930", value: "09:30" },
  { id: "1000", value: "10:00" },
  { id: "1030", value: "10:30" },
  { id: "1100", value: "11:00" },
  { id: "1130", value: "11:30" },
  { id: "1200", value: "12:00" },
  { id: "1230", value: "12:30" },
  { id: "1300", value: "13:00", selected: true },
  { id: "1330", value: "13:30" },
  { id: "1400", value: "14:00" },
  { id: "1430", value: "14:30" },
  { id: "1500", value: "15:00" },
  { id: "1530", value: "15:30" },
  { id: "1600", value: "16:00" },
  { id: "1630", value: "16:30" },
  { id: "1700", value: "17:00" },
  { id: "1730", value: "17:30" },
  { id: "1800", value: "18:00" },
  { id: "1830", value: "18:30" },
  { id: "1900", value: "19:00" },
  { id: "1930", value: "19:30" },
  { id: "2000", value: "20:00" },
  { id: "2030", value: "20:30" },
  { id: "2100", value: "21:00" },
  { id: "2130", value: "21:30" },
  { id: "2200", value: "22:00" },
  { id: "2230", value: "22:30" },
  { id: "2300", value: "23:00" },
  { id: "2330", value: "23:30" }
];
