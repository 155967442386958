import { useState, useEffect } from "react";

import { isNode } from "../utils";

const useData = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(!isNode ? window.page_data : null);
  });

  return data || {};
};

export default useData;
