import React from "react";

import LazyImg from "../../LazyImg/LazyImg";

import "./styles.scss";

export default ({ images }) =>
  images && images.length > 0 ? (
    <div className="Badges">
      {images.map(image => (
        <LazyImg key={image} className="Badges-image" src={image} />
      ))}
    </div>
  ) : null;
